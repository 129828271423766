import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../search/services/search/search.service';
import { UserService } from 'src/app/feature/user/services/user.service';
import { CommonService } from 'src/app/shared/services/common/common.service';


@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  public searchedKeyword: any;
  public allSearchedData: any;
  public serverError = false;
  private unsubscribe$ = new Subject();
  public allSearchResults: any;
  public correctedKeyword: any;
  public showMoreData: any;
  public showMore = false;
  public splittedKeywords = [];
  private _currentPopover: any;
  @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;
  public allResults: any;
  public correctedWord: string;
  buttonTexts: string[] = [
    'How do I apply for a D-U-N-S Number?',
    'What are the benefits of having a D-U-N-S Number ?',
    'Can I apply for it internationally ?',
    'Duplicate DUNS Investigation'
  ];

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private searchService: SearchService,
    private router: Router,
    private uService: UserService,
    private ngxService: NgxUiLoaderService) {
    this.route?.queryParams?.subscribe(
      (params: Params) => {
        if (this.router.url.indexOf('/search/search-results') > -1) {
          this.searchedKeyword = params['keyword'];
          this.splittedKeywords = this.searchedKeyword.split(" "); 
          this.getAllSearchTask(this.searchedKeyword);
        }
      }
    )
  }

  ngOnInit(): void {

    this.searchedKeyword = this.route?.snapshot?.queryParamMap?.get('keyword');

    //  // for GA
     this.uService.emitEvent(this.searchedKeyword, "Searched Keyword", "SearchSuccess", 8);
    //  // for GA
    this.getAllSearchTask(this.searchedKeyword);
    this.commonService.initQualtrics();
  }

  public closeOldPopover(popover: any): void {
    if (this._currentPopover && this._currentPopover !== popover) {
      this._currentPopover.hide();
    }
  
    this._currentPopover = popover;
  }

  /**
   * @description Method to get all search data from API
   */
  public getAllSearchTask(keyword: string): void {
    this.ngxService.start();
    this.searchService.getAllSearchData(keyword)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          this.ngxService.stop();
          this.allSearchedData = resp;
          this.getCorrectedKeyword();
          this.allSearchResults = resp.searchRecords;
          this.allResults = this.allSearchResults;
          this.allResults = this.allResults?.map((item: any) => {
            item['highlight.Title'] = item['highlight.Title']?.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
              .replaceAll("&#x2F;", "/").replaceAll("&amp;reg;", "®");
            return item;
          });
          this.allSearchResults = this.allResults;
          this.allSearchResults = resp.searchRecords?.slice(0, 30);
          this.showMore = this.allSearchedData?.searchRecords?.length > 30 ? true : false;
          this.showMoreData = resp.searchRecords?.slice(0, 50);
          this.searchService.emitData({ data: true });
        }
      },
        (err) => {
          this.ngxService.stop();
          if (err?.error?.StatusCode === "500") {
            this.serverError = true;
          }
        });
  }

  /**
   * Method to show more search results
   */
  public showMoreContent(): void {
    this.allSearchResults = this.showMoreData;
    this.showMore = this.allSearchResults?.length > 30 ? false : true;
  }

  /**
   * Method to redirect to content page
   * @param title selected title
   */
  public redirectTitle(title: string): void {
    this.getCorrectedKeyword();
    const keywordSearched = this.correctedKeyword ? this.correctedKeyword : this.searchedKeyword;
    const keyword = title.replace(/[^a-zA-Z ]/g, "") + " or " + keywordSearched;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    this.router.navigate(["/search/article-content"],
      { queryParams: { keyword: keyword, title: title, id: currentTimeInSeconds } });
  }

  /**
   * Method to get the corrected keyword
   */
  public getCorrectedKeyword(): void {
    this.correctedKeyword = "";
    this.correctedWord = this.allSearchedData?.metadata?.entityMetadata[0]?.spellCorrectionMetadata?.correctedQuery;
    if (this.allSearchedData?.metadata && this.correctedWord) {
      this.correctedKeyword = this.correctedWord ? this.correctedWord : "";
    }
  }

}
