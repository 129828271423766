<div class="col-sm-12 col-md-8 pr-0 mt-3 faq-container pl-0" *ngIf="faqContent && faqContent.length > 0">
  <accordion>
    <accordion-group #group1 [isOpen]="false">
      <div class="btn btn-link btn-block clearfix p-0" accordion-heading type="button">
        <div class="d-flex justify-content-between">
          <div class="pull-left float-left text-xl align-content-left title-color">
            <img class="mb-1" height="24" width="24" src="../../../../assets/images/feature/help_outline.png"
              alt="help icon" />
            Frequently Asked Questions
          </div>
          <span accordion-heading class="float-right">
            <span class="float-right pull-right pt-1">
              <i class="fa" [ngClass]="group1?.isOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
            </span>
          </span>
        </div>
      </div>
      <div class="faq-content" id="faq-content-{{ selectedTab?.active ? selectedTab.TAB_ID : 'x' }}">
        <accordion [closeOthers]="true">
          <accordion-group *ngFor="let faq of faqContent;let i = index" #group1 [isOpen]="false" (isOpenChange)="accordionEvent($event, i)">
            <div class="btn btn-link btn-block clearfix p-0" accordion-heading type="button" (click)="toggleAccordion()">
              <div class="d-flex justify-content-between">
                <div class="pull-left float-left text-md align-content-left title-color" 
                *ngIf="finalData; else productFaqTitle">
                  <span class="text-transf" [innerHTML]="faq.title"
                  id="faq-title-{{selectedTab.active?selectedTab.TAB_ID:'x'}}-0{{group1?.isOpen?i+1:'x'}}"></span>
                </div>
                <ng-template #productFaqTitle>
                  <div class="pull-left float-left text-md align-content-left title-color">
                    <span class="text-transf" [innerHTML]="faq.title"></span>
                  </div>
                </ng-template>
              <span accordion-heading class="float-right">
                <span class="float-right pull-right pt-1">
                  <i class="fa" [ngClass]="group1?.isOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
                </span>
              </span>
              </div>
            </div>
            <div class="title-color" [innerHTML]="faq.content"></div>
            <div class="col-sm-12 pl-0 mt-3" id="q-faq-{{selectedTab.active?selectedTab.TAB_ID:'x'}}-0{{group1?.isOpen?i+1:'x'}}"
            *ngIf="finalData; else productFaqQualtrics">
            <!-- Feedback Section -->
            
          

              <!-- <div class="text-md pr-3 title-color bottom-margin">Was this helpful? <span class="text-md pt-3">
                  <img class="mr-2 ml-2 pointer" height="24" width="24" src="{{faq.thumbUpImageSrc}}" (click)="changeImage(i, 'thumbsUp')"
                    alt="thumb_up" />
                  <img class="pointer mt-1" height="24" width="24"
                    src="{{faq.thumbDownImageSrc}}" alt="thumb_down" (click)="changeImage(i, 'thumbsDown')"/>
                </span>
              </div> -->
            </div>
            <ng-template #productFaqQualtrics>
              <div class="col-sm-12 pl-0 mt-3 mb-3" id="q-faq-TAB_02-0{{group1?.isOpen?i+1:'x'}}"></div>
            </ng-template>
          </accordion-group>
        </accordion>
      </div>
    </accordion-group>
  </accordion>
</div>
