import { Injectable, Injector } from "@angular/core";
import { SERVICE_GLOBAL } from "src/app/shared/constants/services.global";
import { Observable, Subject, map, switchMap } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class HomeService {
  public serviceGlobal: any;
  private data = new Subject<any>();
  public data$ = this.data.asObservable();
  
  constructor(private http: HttpClient, private injector: Injector) {
    this.serviceGlobal = this.injector.get(SERVICE_GLOBAL);
  }

	/**
	 * @description API call to get home tab data
	 * @param entityId Entity ID
	 */
  public getHomeData (): Observable<any> {
    return this.http.get(this.serviceGlobal.HOME_DATA);
  }

  /**
   * @description API call to get the SFDC data
   */
  public getSfdcData (): Observable<any> {
    return this.http.get(this.serviceGlobal.SFDC_DATA);
  }

  /**
   * @description Five9 API to request a call
   * @param url base url
   * @param data form data
   */
  public requestCall (f9CallGroup: any, number: any, data: any): Observable<any> {
    let url = this.serviceGlobal.FIVE9_URL + "?F9CallGroup=" + f9CallGroup + "&number1=" + number;
    if (data.firstName) {
      url = url + '&first_name=' + data?.firstName;
    } if (data.lastName) {
      url = url + '&last_name=' + data?.lastName;
    } if (data.companyName) {
      url = url + '&company=' + data?.companyName;
    } if (data.emailID) {
      url = url + '&email=' + data?.emailID;
    }
    return this.http.post(url, {});
  }

  /**
   * @description API to create the user using idaas api if not registered
   * @param formData Details entered by user
   * @param number Phone number of user
   */
 public createSsoUser (formData: any, number: any): Observable<any> {
   let url = this.serviceGlobal.CREATE_SSO_USER;
   if (formData.firstName) {
     url = url + '?firstName=' + formData?.firstName;
   }if (formData.lastName) {
    url = url + '&lastName=' + formData?.lastName;
  } if (formData.companyName) {
    url = url + '&companyName=' + formData?.companyName;
  } if (formData.emailID) {
    url = url + '&emailId=' + formData?.emailID;
  } if (formData.telephoneNumber) {
    url = url + '&phoneNumber=' + number;
  }
   return this.http.post(url, {});
 }

 /**
  * @description  API to get the user using idaas api
  * @param emailId Email id of user
  */
 public getUser(emailId: string): Observable<any> {
  const url = this.serviceGlobal.GET_USER + '?emailId=' + emailId;
  return this.http.get(url);
 }

 public getGainsightUser(email: string): Observable<any> {
  const url = '/api/dev/contact/getContacts?email=' + email;

  const apiKey = 'Ekm1zPmWUd7ix6lO7bR142X7A0Hvzp0M81YtkxFY';

  const headers = new HttpHeaders()
    .set('x-api-key', apiKey);

  return this.http.get(url, { headers });
}

 /**
  * API to add the access for service.dnb.com for user who already registered with dnb but no access of service.dnb.com
  * @param emailId Email id of user
  */
 public addProductAccess(emailId: string): Observable<any> {
  const url = this.serviceGlobal.ADD_PRODUCT_ACCESS + '?emailId=' + emailId;
  return this.http.put(url, {});
 }

  /**
  * Method to emit the data from one component to another
  * @param data 
  */
  public emitData(data: any): void {
    this.data.next(data);
  }

  /**
   * @description Case Status API call
   * @param data contain both case number and emailId
   */
  public getCaseStatus (data: any): Observable<any> {
    const caseNumber = data?.isDUNSCheck ? "?" : "?caseNumber=" + data?.caseNumber +"&" ;
    let url = this.serviceGlobal.GET_CASE_STATUS + caseNumber + "emailID=" + data?.emailID +"&isDUNSCheck="+ data?.isDUNSCheck;
    return this.http.post(url, {});
  }

  /**
   * @description Update Case Status API call
   * @param data contain case number, emailId and isResolved
   */
   public updateCaseStatus (data: any): Observable<any> {
    const body = {
      "caseNotes": data?.reason
    }
    const url = this.serviceGlobal.UPDATE_CASE_STATUS + "?caseNumber=" + data?.caseNumber + "&emailID=" + data?.emailID + "&isResolved=" + data?.isResolved;
    return this.http.post(url, body);
  }

}
