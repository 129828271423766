import { AfterViewInit, Component, OnInit, TemplateRef,ElementRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SearchService } from "../../../../search/services/search/search.service";
import { UserService } from "src/app/feature/user/services/user.service";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { CommonService } from "src/app/shared/services/common/common.service";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { HttpClient } from "@angular/common/http";

import { DomSanitizer } from '@angular/platform-browser'
declare let pdfMake: any;

// import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: "app-article-content",
  templateUrl: "./article-content.component.html",
  styleUrls: ["./article-content.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleContentComponent implements OnInit, AfterViewInit {
  private unsubscribe$ = new Subject();
  public selectedTitle: any;
  public searchedKeyword: any;
  public searchedData: any;
  public searchRecords = [];
  public titleSelected: string;
  public relatedArticles = [];
  public selectedContent: any;
  public serverError = false;
  public searchedTitle: string;
  public relatedArticlesLength: number;
  public showCreateSsoForm = false;
  public tabDetails: any;
  public modalRef: BsModalRef;
  public locationData: any;
  public locationIp: any;
  public locationCountry: any;
  public isSelectedContent: Subject<boolean> = new Subject();
  public clickedTitle: any;
  public showCaseStatus: boolean = false;
  @ViewChild('pdfArticle') pdfArticle: ElementRef;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public authService: AuthService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
    private modalService: BsModalService,
    public http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (this.router.url.indexOf("/search/article-content") > -1) {
        this.searchedKeyword = params["keyword"];
        this.selectedTitle = params["title"];
        this.showCreateSsoForm = false;
        const check = " " + "or" + " ";
        if (this.searchedKeyword.includes(check) != true) {
          // for GA
          this.userService.emitEvent(
            this.searchedKeyword,
            "Searched Keyword",
            "SearchSuccess",
            8
          );
          // for GA
        }
        this.getSearchTask(this.searchedKeyword, this.selectedTitle);
      }
    });
  }

  ngAfterViewInit(): void {
    this.isSelectedContent.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
      const paragraph = document.getElementById("search-text");
      const anchor = paragraph?.getElementsByTagName("a");
      const arr = anchor ? Array.from(anchor) : [];
      for (let i = 0; arr && i < arr?.length; i++) {
        arr[i].removeEventListener("click", this.handleAnchorClick.bind(this));
        arr[i].addEventListener(
          "click",
          this.handleAnchorClick.bind(this, arr[i])
        );
      }
    });
  }

  /**
   * Method to handle click in article content anchor tags
   */
  public handleAnchorClick(test: any): void {
    // for GA
    const sfdcLink = test?.getAttribute("href");
    this.clickedTitle = test.innerHTML.replace("&nbsp;", " ");
    this.clickedTitle = this.clickedTitle
      .replace("<u>", " ")
      .replace("</u>", " ");
    const heading = document.getElementById("search-header")?.innerHTML;
    // for GA
    this.userService.emitEvent(heading, heading + ">>>" + this.clickedTitle + ">>>" + sfdcLink, "SearchArticle", 11);
    // for GA
  }

  /**
   * @description Method to get search data from API
   */
  public getSearchTask(keyword: string, title: string): void {
    this.ngxService.start();
    this.showCaseStatus = false;
    this.searchService
      .getSearchData(keyword, title)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp) {
            this.ngxService.stop();
            this.showCaseStatus = true;
            this.searchedData = resp;
            this.searchRecords = resp.searchRecords;
            this.titleSelected = this.selectedTitle;
            this.getArticleDetails();
          }
        },
        (err) => {
          this.ngxService.stop();
        }
      );
  }

  /**
   * Method to filter selected article and related article
   */
  public getArticleDetails(): void {
    this.relatedArticles = this.searchRecords?.filter((item) => {
      return item["Title"] !== this.titleSelected;
    });
    this.selectedContent = this.searchRecords?.filter((item) => {
      return item["Title"] === this.titleSelected;
    });
    if (this.selectedContent && this.selectedContent[0]) {
      this.selectedContent =  this.sanitizer.bypassSecurityTrustHtml(this.selectedContent[0]["Content"]);
       this.selectedContent
      //console.log(this.selectedContent.match(/<a(.)>.*?<\/a\1>/g))
    }
    if (this.relatedArticles) {
      this.relatedArticles = this.relatedArticles.slice(0, 5);
      this.relatedArticlesLength = this.relatedArticles
        ? this.relatedArticles?.length
        : 0;
    }
    this.searchService.emitData({ data: true });
    setTimeout(() => {
      this.commonService.initQualtrics();
      this.isSelectedContent.next(true);
    }, 1500);
  }

  /**
   * Method to display toggle related article on click
   * @param relatedArticleTitle selected related article
   */
  public displayArticle(relatedArticleTitle: { Title: string }): void {
    this.showCreateSsoForm = false;
    this.titleSelected = relatedArticleTitle["Title"];
    this.getArticleDetails();
  }

  /**
   * Method to show the create sso form
   */
  public showCreateSso(): void {
    this.showCreateSsoForm = true;
  }

  /**
   * Method to get the tab details of logged in and non logged in users
   */
  public getTabDetails(): void {
    this.searchService
      .getTabDetails(this.titleSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          this.tabDetails = resp;
          localStorage.setItem("tab_details", JSON.stringify(this.tabDetails));
          if (this.authService.isAuthenticated()) {
            //get tab details of logged in users
            this.router.navigate(["/home"], {
              queryParams: { TAB_ID: this.tabDetails["TAB_ID"] },
            });
          } else if (!this.authService.isAuthenticated()) {
            //get tab details of non logged in users
            this.userService.doLogin(this.tabDetails["TAB_ID"]);
          }
        }
      });
  }

  /**
   * Method to open sign in modal
   * @param template template to open
   */
  public openModal(template: TemplateRef<any>): void {
    const config: ModalOptions = { class: "modal-dialog-centered modal-lg" };
    this.modalRef = this.modalService.show(template, config);
  }

  /**
   * Method to download article as PDF
   */

  async downloadAsPDF(): Promise<void> {
    const htmlToPdfmake = (await import('html-to-pdfmake')).default;
  
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
    const pdfArticle = this.pdfArticle.nativeElement;
    const html = htmlToPdfmake(pdfArticle.innerHTML);
  
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download(this.titleSelected);
  }
}
