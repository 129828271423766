<div class="main-container">
  <header>
    <app-header></app-header>
  </header>
  <div class="middle-container">
    <ngx-ui-loader [hasProgressBar]="false" [fgsTemplate]="dnbLoader" [overlayColor]="'rgba(255, 255, 255, 0.8)'">
    </ngx-ui-loader>
    <ng-template #dnbLoader>
      <img [class.lazy]="true" src="../assets/images/common/dnb-loading.gif" alt="D&B loading indicator" />
    </ng-template>
    <app-modal [(modalPopupName)]="showModal">
    </app-modal>
    <router-outlet></router-outlet>
  </div>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
