import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class DriftEventService {

    constructor(private router: Router) {
    }

    public init() {
        try {

            const script2 = document.createElement('script');
            script2.innerHTML = `
                    var isOpen = false;
                    /* 
                    * Description - Function to load drift account with email id authentication
                    */
                    function DriftBot() {
                    if (isOpen === false) {
                        var t = window.driftt = window.drift = window.driftt || [];
                        if (!t.init) {
                        if (t.invoked) {
                            isOpen = true;
                            return void (window.console && console.error && console.error("Drift snippet included twice."));
                        }
                        t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
                            t.factory = function (e) {
                            return function () {
                                var n = Array.prototype.slice.call(arguments);
                                return n.unshift(e), t.push(n), t;
                            };
                            }, t.methods.forEach(function (e) {
                            t[e] = t.factory(e);
                            }), t.load = function (t) {
                            var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                            o.type = "text/javascript", o.async = "true", o.defer = "true", o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                            var i = document.getElementsByTagName("script")[0];
                            i.parentNode.insertBefore(o, i);
                            };
                        }
                        drift.SNIPPET_VERSION = '0.3.1';
                        if (JSON.parse(localStorage['licensedUser']) === "true") {
                        var userData = JSON.parse(localStorage['_user']);
                        if (userData) {
                            drift.identify(userData["emailID"], {
                            email: "emailID" in userData ? userData["emailID"] : "",
                            first_name: "firstName" in userData ? userData["firstName"] : "",
                            last_name: "lastName" in userData ? userData["lastName"] : "",
                            employment_name: "companyName" in userData ? userData["companyName"] : "",
                            phone: "phoneNumber" in userData ? userData["phoneNumber"] : "",
                            support_case_origin: '` + environment.driftCaseOrigin + `',
                            support_channel_identifier: "total_channel_identifier" in userData ? userData["total_channel_identifier"] : ""
                            },
                            { userJwt: "drift_token" in userData ? userData["drift_token"] : "" });
                        }
                        }
                        drift.load('` + environment.driftAccount + `');
                        isOpen = true;
                        drift.on('ready', function (api) {
                        drift.api.widget.hide();
                        })
                    }
                
                    };
                
                    /* 
                    * Description - Function to load playbook worklow based on reason/subreason selection
                    */
                    function playBookLoad() {
                    if (JSON.parse(localStorage['licensedUser']) !== "true") {
                        DriftBot();
                    }
                    if (window.drift) {
                        window.drift.on('ready', function (api) {
                        drift.api.widget.show();
                        let firstOption = JSON.parse(sessionStorage.getItem("firstDropOption"))
                        let secondOption = JSON.parse(sessionStorage.getItem("secondDropOption"));
                        let thirdOption = JSON.parse(sessionStorage.getItem("thirdDropOption"));
                        if (JSON.parse(localStorage['licensedUser']) === "true") {
                            drift.api.setUserAttributes({
                            'reason': firstOption ? firstOption : "",
                            'subreason': secondOption ? secondOption : "",
                            'subreason_1': thirdOption ? thirdOption : ""
                            })
                        } else {
                            drift.api.setUserAttributes({
                            'support_case_origin': '` + environment.driftCaseOrigin + `',
                            'reason': firstOption ? firstOption : "",
                            'subreason': secondOption ? secondOption : "",
                            'subreason_1': thirdOption ? thirdOption : ""
                            })
                        }
                        drift.api.startInteraction({ interactionId: ` + environment.driftPlaybook + `, goToConversation: true });
                        })
                    }
                    }
                
                    /* 
                    * Description - Function to hide playbook always when user select changes
                    */
                    function playBookHide() {
                    if (window.drift) {
                        window.drift.on('ready', function (api) {
                        drift.api.widget.hide();
                        });
                    }
                    }
                
                    /* 
                    * Description - Function to remove drift user details when user logout
                    */
                    function logoutDrift() {
                    if (window.drift) {
                        playBookHide();
                        drift.reset();
                    }
                    }
                `;
            document.head.appendChild(script2);

        } catch (ex) {
            console.error(ex);
        }
    }

}