<div *ngIf="showLoader" class="dnbi-loader-container">
  <div class="dnbi-loader-gif"></div>
  <img [class.lazy]="true" src="../assets/images/common/dnb-loading.gif" height="60" width="60" alt="loading indicator" />
</div>
<div *ngIf="!serverError" class="d-flex flex-column main-container">
  <div class="banner-container">
    <div
      class="frequent-title-color text-lg banner-font banner-margin text-center"
    >
      Digital Service Center
    </div>
  </div>

  <app-help-topics [featureList]="featureList"></app-help-topics>
  <div class="home-container">
    <div class="container-fluid ml-0 mr-0" (mouseenter)="hideDropdown()">
    <div class="row">
      <div class="mb-4 pl-0 pr-0 col-12 col-md-6 margin-auto">
    <div
      class="frequent-title-color text-3xl banner-font banner-margin support-heading"
    >
      Need help with something else?
    </div>
    
    </div>
    <div class="pl-0 pr-0 col-md-5 credibility-head">
      <div class="ml-5 credibility">
        <p class="credibility-concierge mb-0">
          <span class="">Expand Your Online Presence</span>
        </p>
        <p class="mb-0 credibility-concierge-second">
          <span>with Dun & Bradstreet Business Listing Plus</span>
          <span class="text-right float-right">
            <a
              href="https://www.dnb.com/products/small-business/business-listing-category.html"
              target="_blank" rel="noopener noreferrer" class="credibilitybutton" aria-label="Learn More, opens in a new window"
            >
              Learn More
              <img _ngcontent-vew-c67="" height="24" width="24" src="../../../../assets/images/feature/Link_arrow.png" alt="learn-more-arrow">
          </a>
        </span>
        </p>
        
      </div>
    </div>
  </div>
  </div>
<div class="clear"></div>


<!-- Mouse hover static test end -->

    <div>
      <tabset id="dynamicTabs" class="nav-tabs" height="200" #dynamicTabs>
        <div
      class="frequent-title-color text-md banner-font banner-margin support-heading" (mouseenter)="hideDropdown()"
    >
      Please choose one of the three tabs and hover on the selected tab to select an option that best describes what you
      need help with
    </div>
        <tab
          *ngFor="let item of channelMapData"
          class="tab-text tab-drop"
          (selectTab)="changeTab(item); item.active = true"
          (deselect)="deselectTab(item); item.active = false"
          [active]="item.active"
          [id]="item.TAB_ID!"
          aria-labelledby="item.TAB_ID!"
          aria-label="tab"
        >
        <ng-template tabHeading>
            <div
              class="tab-name text-md"
              (click)="handleClick(item)"
              [innerHTML]="item.TAB_NAME"
              (mouseenter)="handleMouseOver(item)"
            >
              {{ item.TAB_NAME }}
            </div>
          </ng-template>
          <div class="selected-content mt-2" *ngIf="selectedOptionNew" (mouseenter)="hideDropdown()">
            <p class="selectedOption"><span [innerHTML]="selectedOptionNew">{{ selectedOptionNew }}</span></p>
          </div>
          <div class="first-div" *ngIf=showDropdownFlag>
            <div class="second-div" (mouseenter)="showDropdown(item)">
            <div class="dropdown" (mouseenter)="showDropdown(item)">
              <ul class="dropdown-list"  (mouseenter)="showDropdown(item)" *ngIf=showDropdownFlag>
                <li *ngFor="let option of channelList" (click)="selectOption(option)" 
                [innerHTML]="option.reason">
                  {{ option.reason }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid ml-0 mr-0 pl-0" (mouseenter)="hideDropdown()">
          
            <div class="mb-4 pl-0 pr-0 col-12 col-md-6" (mouseenter)="hideDropdown()">
        <div class="mt-4"
        *ngIf="firstDropData && secondChannelList && secondChannelList?.length > 0"
      >
        <div class="text-md title-text pb-2">Make your selection below</div>
        <div aria-label="second dropdown">
          <ng-select
            class="ng-select"
            [items]="secondChannelList"
            [(ngModel)]="secondDropData"
            placeholder="Please Select​​​​"
            bindLabel="subreason"
            [clearable]="false"
            (ngModelChange)="changeSecondOption($event);clearData()"
            [searchable]="false"
            id="second-drop"
          >
          </ng-select>
        </div>
        </div>
        <div
          class="pt-3"
          *ngIf="
            secondDropData && thirdChannelList && thirdChannelList?.length > 0
          "
        >
        <div aria-label="third dropdown">
          <ng-select
            class="ng-select"
            [items]="thirdChannelList"
            [(ngModel)]="thirdDropData"
            placeholder="Please Select​​​​"
            bindLabel="subreason"
            [clearable]="false"
            (ngModelChange)="selectedThirdOption($event);clearData()"
            [searchable]="false"
            id="third-drop"
          >
          </ng-select>
        </div>
        </div>
      </div>

    
        </div>
        <!-- </div> -->
          <div>
            <app-drop-down-panel
              (selectedOption)="dropDownLoaded($event)"
              (mouseenter)="hideDropdown()"
            ></app-drop-down-panel>

            <div class="dropdown-content" *ngIf="toShow">
              <div class="row text-3xl pb-2 m-0 title-color" *ngIf="dykContent">
                <span
                  [innerHTML]="optionHeading"
                  id="dyk-title-{{ item.active ? item.TAB_ID : 'x' }}"
                ></span>
              </div>
              <div
                class="row dyk-border col-sm-12 col-md-8 ml-0 card-padding word-break"
                *ngIf="dykContent"
              >
                <div
                  class="col-sm-12 text-xl semi-bold mt-2 pl-0 dyk-title-color"
                >
                  <img
                    class="mr-1"
                    height="24"
                    width="24"
                    src="../../../../assets/images/feature/light_bulb.png"
                    alt="light_bulb"
                  />
                  Did you know?
                </div>
                <div
                  id="dyk-content-{{ item.active ? item.TAB_ID : 'x' }}"
                  class="text-color text-md margin-top-20"
                  [innerHTML]="dykContent"
                >
                  {{ dykContent }}
                </div>
                <div
                  class="col-sm-12 mt-3 p-0"
                  id="q-dyk-{{ item.active ? item.TAB_ID : 'x' }}"
                ></div>
              </div>
            </div>

            <!--FAQ section-->
            <app-faq
              [selectedFinalData]="finalData"
              [dykData]="dykData"
              [selectedTab]="item"
            ></app-faq>

            <app-login-section
              [selectedTab]="selectedTab"
              *ngIf="
                ((finalData?.data?.primary &&
                  finalData?.data?.primary !== '') ||
                  (finalData?.data?.secondary &&
                    finalData?.data?.secondary !== '')) &&
                  !authService.isAuthenticated() &&
                  !commonService.getNonSso();
                else loggedInSection
              "
              [finalData]="finalData"
            >
            </app-login-section>

            <ng-template #loggedInSection>
              <div *ngIf="!commonService.isInternalUser()">

                <!-- CASE section-->
                <div class="col-sm-12 col-md-8 p-0" *ngIf="finalData?.data?.caseForm">
                  <app-case-status-section
                    [selectedFinalData]="finalData"
                  ></app-case-status-section>
                </div>
          
                <!--primary and secondary channel-->
                <ng-template #requestCallFormPrimary>
                  <form
                    [formGroup]="phoneForm"
                    (ngSubmit)="requestCall(finalData?.data)"
                  >
                  <fieldset [disabled]="fieldsetDisabled && finalData?.data?.hidePhoneFormText" [ngClass]="{'disbaleform':fieldsetDisabled && finalData?.data?.hidePhoneFormText}">
                    <div
                      class="row mt-3 m-0 dyk-border phone-container col-sm-12 col-md-8 card-padding"
                      *ngIf="finalData?.data?.primary && showFormForPrimary"
                    >
                      <div class="col-sm-12 text-xl semi-bold pt-2 title-color">
                        Best way to get help
                      </div>
                        <alert
                        class="mt-2 alert-width"
                        *ngIf="showNotification"
                        type="info"
                        [dismissible]="true"
                        (onClosed)="clearMessage()"
                      >
                        <img
                          [class.lazy]="true"
                          class="mr-2 mb-1"
                          height="20"
                          width="20"
                          src="../../../../assets/images/feature/info.png"
                          alt="notification message info"
                        />
                        <span>{{ notificationMessage }}</span>
                      </alert>
                      <alert
                        class="mt-2 alert-width"
                        *ngIf="errorMessage"
                        type="error"
                        [dismissible]="true"
                        (onClosed)="clearMessage()"
                      >
                        <img
                        [class.lazy]="true"
                          class="mr-2 mb-1"
                          height="20"
                          width="20"
                          src="../../../../assets/images/feature/error.png"
                          alt="five9 error"
                        />
                        <span>{{ errorMessage }}</span>
                      </alert>
                      <alert
                        class="mt-2 alert-width"
                        *ngIf="warningMessage"
                        type="warning"
                        [dismissible]="true"
                        (onClosed)="clearMessage()"
                      >
                        <img
                          class="mr-2 mb-1"
                          [class.lazy]="true"
                          height="20"
                          width="20"
                          src="../../../../assets/images/feature/warning.png"
                          alt="five9 warning"
                        />
                        <span>{{ warningMessage }}</span>
                      </alert>
                      <div
                        class="col-xs-12 col-sm-12 text-md mt-3 text-color pl-0"
                      >
                        {{ finalData?.data?.primary_phoneFormText }}
                      </div>
                      <div class="col-sm-6 mt-3 pl-0">
                        <div class="text-md text-color">First Name*</div>
                        <div class="text-md mt-2">
                          <input
                            class="form-control input-box"
                            type="text"
                            formControlName="firstName"
                            [ngClass]="{
                              invalid:
                                showValidation &&
                                submitted &&
                                f.firstName.errors
                            }"
                          />
                        </div>
                        <div
                          *ngIf="
                            showValidation && submitted && f.firstName.errors
                          "
                          class="danger-text"
                        >
                          <div *ngIf="f.firstName.errors.required">
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary first name error"
                            />
                            First Name is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 mt-3 pl-0">
                        <div class="text-md text-color">Last Name*</div>
                        <div class="text-md mt-2">
                          <input
                            class="form-control input-box"
                            type="text"
                            formControlName="lastName"
                            [ngClass]="{
                              invalid:
                                showValidation && submitted && f.lastName.errors
                            }"
                          />
                        </div>
                        <div
                          *ngIf="
                            showValidation && submitted && f.lastName.errors
                          "
                          class="danger-text"
                        >
                          <div *ngIf="f.lastName.errors.required">
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary last name error"
                            />
                            Last Name is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 mt-3 pl-0">
                        <div class="text-md text-color">
                          Business Email Address*
                        </div>
                        <div class="text-md mt-2">
                          <input
                            class="form-control input-box"
                            type="text"
                            formControlName="emailID"
                            [ngClass]="{
                              invalid:
                                showValidation && submitted && f.emailID.errors
                            }"
                          />
                        </div>
                        <div
                          *ngIf="
                            showValidation && submitted && f.emailID.errors
                          "
                          class="danger-text"
                        >
                          <div *ngIf="f.emailID.errors.required">
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary email error"
                            />
                            Business Email Address is required
                          </div>
                          <div *ngIf="f.emailID.errors.email">
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary valid email error"
                            />
                            Business Email Address must be a valid email address
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 mt-3 pl-0">
                        <div class="text-md text-color">Telephone Number*</div>
                        <ngx-intl-tel-input
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="false"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="
                            CountryISO[locationCountry]
                              ? CountryISO[locationCountry]
                              : CountryISO.UnitedStates
                          "
                          [maxLength]="15"
                          [phoneValidation]="true"
                          [separateDialCode]="true"
                          [numberFormat]="PhoneNumberFormat.National"
                          name="telephoneNumber"
                          class="form-control form-control-padding input-box mt-2"
                          formControlName="telephoneNumber"
                          [ngClass]="{
                            invalid:
                              showValidation &&
                              submitted &&
                              f.telephoneNumber.errors
                          }"
                        >
                        </ngx-intl-tel-input>
                        <div
                          *ngIf="
                            showValidation &&
                            submitted &&
                            f.telephoneNumber.errors
                          "
                          class="danger-text"
                        >
                          <div *ngIf="f.telephoneNumber.errors.required">
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary phone error"
                            />
                            Telephone Number is required
                          </div>
                          <div
                            *ngIf="
                              !f.telephoneNumber.errors.required &&
                              !f.telephoneNumber.valid
                            "
                          >
                            <img
                              class="mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="primary valid phone error"
                            />
                            Telephone Number is not valid
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 mt-3 pl-0">
                        <div class="text-md text-color">Company Name</div>
                        <div class="text-md mt-2">
                          <input
                            class="form-control input-box"
                            type="text"
                            formControlName="companyName"
                          />
                        </div>
                      </div>

                      <div class="row pl-3 text-md mt-4 text-color pl-0 card-padding" *ngIf="fieldsetDisabled && finalData?.data?.hidePhoneFormText">
                        {{ finalData?.data?.primary_outOfOfficeHoursText }}
                      </div>
                      <div
                        class="col-xs-12 col-sm-12 mt-3 mb-3 text-md pr-0 pl-0 d-flex justify-content-xs-start justify-content-md-end"
                      >
                        <button
                          type="submit"
                          class="btn btn-primary float-right btn-height"
                        >
                          {{ finalData?.data?.primary }}
                        </button>
                      </div>

                     

                    <!-- </div> -->
                    </div>
                    </fieldset>
                  </form>
                </ng-template>

                <ng-template #requestCallFormSecondary>
                  <div
                    class="row mt-3 m-0 phone-secondary-container col-sm-12 col-md-8 pl-0 pr-0"
                    *ngIf="finalData?.data?.secondary && showFormForSecondary"
                  >
                    <accordion>
                      <accordion-group #group1 [isOpen]="false">
                        <button
                          class="btn btn-link btn-block clearfix p-0"
                          accordion-heading
                          type="button"
                        >
                          <div class="d-flex justify-content-between">
                            <div
                              class="pull-left float-left text-xl align-content-left"
                            >
                              More ways to get help
                            </div>
                            <span accordion-heading class="float-right">
                              <span class="float-right pull-right pt-1">
                                <i
                                  class="fa"
                                  [ngClass]="
                                    group1?.isOpen
                                      ? 'fa-angle-up'
                                      : 'fa-angle-down'
                                  "
                                ></i>
                              </span>
                            </span>
                          </div>
                        </button>
                        <form
                          [formGroup]="phoneForm"
                          (ngSubmit)="requestCall(finalData?.data)"
                        >
                        <fieldset [disabled]="fieldsetDisabled && finalData?.data?.hidePhoneFormText"  [ngClass]="{'disbaleform':fieldsetDisabled && finalData?.data?.hidePhoneFormText}">
                          <alert
                            class="mt-2"
                            *ngIf="showNotification && notificationMessage"
                            type="info"
                            [dismissible]="true"
                            (onClosed)="clearMessage()"
                          >
                            <img
                              class="mr-2 mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/info.png"
                              alt="message info"
                            />
                            <span>{{ notificationMessage }}</span>
                          </alert>
                          <alert
                            class="mt-2"
                            *ngIf="errorMessage"
                            type="error"
                            [dismissible]="true"
                            (onClosed)="clearMessage()"
                          >
                            <img
                              class="mr-2 mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/error.png"
                              alt="secondary error"
                            />
                            <span>{{ errorMessage }}</span>
                          </alert>
                          <alert
                            class="mt-2"
                            *ngIf="warningMessage"
                            type="warning"
                            [dismissible]="true"
                            (onClosed)="clearMessage()"
                          >
                            <img
                              class="mr-2 mb-1"
                              [class.lazy]="true"
                              height="20"
                              width="20"
                              src="../../../../assets/images/feature/warning.png"
                              alt="secondary warning"
                            />
                            <span>{{ warningMessage }}</span>
                          </alert>
                          <div
                            class="col-xs-12 col-sm-12 text-md text-color pl-0"
                          >
                            {{ finalData?.data?.secondary_phoneFormText }}
                          </div>
                          <div class="row">
                            <div class="col-sm-6 col-md-6 mt-3">
                              <div class="text-md text-color">First Name*</div>
                              <div class="text-md mt-2">
                                <input
                                  class="form-control input-box"
                                  type="text"
                                  formControlName="firstName"
                                  [ngClass]="{
                                    invalid:
                                      showValidation &&
                                      submitted &&
                                      f.firstName.errors
                                  }"
                                />
                              </div>
                              <div
                                *ngIf="
                                  showValidation &&
                                  submitted &&
                                  f.firstName.errors
                                "
                                class="danger-text"
                              >
                                <div *ngIf="f.firstName.errors.required">
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary first name error"
                                  />
                                  First Name is required
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 mt-3 pl-3">
                              <div class="text-md text-color">Last Name*</div>
                              <div class="text-md mt-2">
                                <input
                                  class="form-control input-box"
                                  type="text"
                                  formControlName="lastName"
                                  [ngClass]="{
                                    invalid:
                                      showValidation &&
                                      submitted &&
                                      f.lastName.errors
                                  }"
                                />
                              </div>
                              <div
                                *ngIf="
                                  showValidation &&
                                  submitted &&
                                  f.lastName.errors
                                "
                                class="danger-text"
                              >
                                <div *ngIf="f.lastName.errors.required">
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary last name error"
                                  />
                                  Last Name is required
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 mt-3">
                              <div class="text-md text-color">
                                Business Email Address*
                              </div>
                              <div class="text-md mt-2">
                                <input
                                  class="form-control input-box"
                                  type="text"
                                  formControlName="emailID"
                                  [ngClass]="{
                                    invalid:
                                      showValidation &&
                                      submitted &&
                                      f.emailID.errors
                                  }"
                                />
                              </div>
                              <div
                                *ngIf="
                                  showValidation &&
                                  submitted &&
                                  f.emailID.errors
                                "
                                class="danger-text"
                              >
                                <div *ngIf="f.emailID.errors.required">
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary email error"
                                  />
                                  Business Email Address is required
                                </div>
                                <div *ngIf="f.emailID.errors.email">
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary valid email error"
                                  />
                                  Business Email Address must be a valid email
                                  address
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 mt-3 pl-3">
                              <div class="text-md text-color">
                                Telephone Number*
                              </div>
                              <ngx-intl-tel-input
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="false"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Name]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="
                                  CountryISO[locationCountry]
                                    ? CountryISO[locationCountry]
                                    : CountryISO.UnitedStates
                                "
                                [maxLength]="15"
                                [phoneValidation]="true"
                                [separateDialCode]="true"
                                [numberFormat]="PhoneNumberFormat.National"
                                name="telephoneNumber"
                                class="form-control form-control-padding input-box mt-2"
                                formControlName="telephoneNumber"
                                [ngClass]="{
                                  invalid:
                                    showValidation &&
                                    submitted &&
                                    f.telephoneNumber.errors
                                }"
                              >
                              </ngx-intl-tel-input>
                              <div
                                *ngIf="
                                  showValidation &&
                                  submitted &&
                                  f.telephoneNumber.errors
                                "
                                class="danger-text"
                              >
                                <div *ngIf="f.telephoneNumber.errors.required">
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary phone error"
                                  />
                                  Telephone Number is required
                                </div>
                                <div
                                  *ngIf="
                                    !f.telephoneNumber.errors.required &&
                                    !f.telephoneNumber.valid
                                  "
                                >
                                  <img
                                    class="mb-1"
                                    [class.lazy]="true"
                                    height="20"
                                    width="20"
                                    src="../../../../assets/images/feature/error.png"
                                    alt="secondary valid phone error"
                                  />
                                  Telephone Number is not valid
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 mt-3">
                              <div class="text-md text-color">Company Name</div>
                              <div class="text-md mt-2">
                                <input
                                  class="form-control input-box"
                                  type="text"
                                  formControlName="companyName"
                                />
                              </div>
                            </div>
                          </div>


                          <div class="row pl-3 text-md mt-3 text-color pl-0 card-padding" *ngIf="fieldsetDisabled && finalData?.data?.hidePhoneFormText">
                            {{ finalData?.data?.secondary_outOfOfficeHoursText }}
                          </div>
                          <div
                            class="col-xs-12 col-sm-12 mt-1 text-md pr-0 pl-0 d-flex justify-content-xs-start justify-content-md-end"
                          >
                            <button
                              type="submit"
                              class="btn btn-light lg-btn-width mb-3 mt-3 box-min-height light-btn"
                            >
                              {{ finalData?.data?.secondary }}
                            </button>
                          </div>

                       

                        </fieldset>
                        </form>
                      </accordion-group>
                    </accordion>
                  </div>
             
                </ng-template>

                <ng-container
                  *ngIf="
                    finalData?.data?.primary &&
                      finalData?.data?.primary !== '' &&
                      !showFormForPrimary;
                    else requestCallFormPrimary
                  "
                >
                  <ng-container *ngIf="finalData?.data?.primary !== 'Submit Case Number' && finalData?.data?.primary !=='Submit'">
                    <div class="col-sm-12 col-md-8 content-border mt-3 ml-0 pl-3">
                      <div
                        class="col-sm-4 text-xl semi-bold title-color mt-3 pl-0 display-inline"
                      >
                        Best way to get help
                      </div>
                      <div
                        class="col-sm-8 display-inline text-align-right pl-0 pr-0"
                      >
                        <button
                          type="button"
                          [ngClass]="{
                            'drift-open-chat': finalData?.data?.primary === 'Chat'
                          }"
                          class="btn btn-primary lg-btn-width mb-3 mt-3 box-min-height"
                          (click)="redirectTo(finalData.data, 'primary', $event)"
                        >
                          {{ finalData?.data?.primary }}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <div
                  class="col-sm-12 col-md-8 content-border mt-3 ml-0 pl-3"
                  *ngIf="
                    finalData?.data?.secondary &&
                      finalData?.data?.secondary !== '' &&
                      !showFormForSecondary;
                    else requestCallFormSecondary
                  "
                >
                  <div
                    class="col-sm-4 text-xl text-xl semi-bold mt-3 pl-0 display-inline title-color"
                    *ngIf="finalData?.data?.secondary"
                  >
                    More ways to get help
                  </div>
                  <div
                    class="col-sm-8 display-inline text-align-right pl-0 pr-0"
                  >
                    <button
                      type="button"
                      [ngClass]="{
                        'drift-open-chat': finalData?.data?.secondary === 'Chat'
                      }"
                      class="btn btn-light lg-btn-width mb-3 mt-3 box-min-height light-btn"
                      (click)="redirectTo(finalData.data, 'secondary', $event)"
                    >
                      {{ finalData?.data?.secondary }}
                    </button>
                  </div>
                </div>
              </div>
              <app-internal-user-section
                *ngIf="
                  commonService.isInternalUser() &&
                  (finalData?.data?.primary || finalData?.data?.secondary)
                "
              ></app-internal-user-section>
            
            </ng-template>
          </div>
        </tab>
      </tabset>
    </div>
    
  </div>
  <div class="col-sm-8 display-inline text-align-right pl-0 pr-0 display-none">
    <button
      type="button"
      class="btn btn-light lg-btn-width mb-3 mt-3 box-min-height light-btn drift-open-chat display-none"
      #myDiv
      id="drift-open-chat" tabindex=0
      onclick="DriftBot()" onkeypress="DriftBot()"
    >
      Chat
    </button>
    <div
      class="load-drift display-none"
      #myDrift
      id="load-drift" tabindex=0
      onclick="playBookLoad()" onkeypress="playBookLoad()"
    >
      Load Drift
    </div>
    <div
      class="hide-drift display-none"
      #hideDrift
      id="hide-drift" tabindex=0
      onclick="playBookHide()" onkeypress="playBookHide()"
    >
      Hide Drift
    </div>
    <div
      class="logout-drift display-none"
      #logoutDrift
      id="logout-drift" tabindex=0
      onclick="logoutDrift()" onkeypress="logoutDrift()"
    >
      Logout Drift
    </div>
  </div>
  <app-helpful-links
    [channelMapDataHelfulLink]="helpfulLinksList"
  ></app-helpful-links>
</div>

<div class="d-flex flex-column main-container" *ngIf="serverError">
  <div class="home-container">
    <div class="icon-margin">
      <img [class.lazy]="true" height="24" width="24" src="../assets/images/feature/power_off.svg" alt="power_off" />
    </div>
    <h2 class="title-color mb-2">
      Sorry, we're down for scheduled maintenance.
    </h2>
    <h5>This page is temporarily unavailable</h5>
  </div>
</div>
