// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: true,
  enableMock: false, // true for mock; else false
  useProxy: false, // true for mock; else false
  mockUrl: "http://localhost:3000",
  apiUrl: "https://api.dev-service.dnb.com", // API URL - DNB Dev
  iDaaSUrl: "https://api.idaas.dnb.com",
  // apiUrl: "https://m2ydq5e7ch.execute-api.us-east-1.amazonaws.com/divr-dev", // API URL - CTS Dev
  oktaUrl: "https://qa.sso.dnb.com/oauth2/ausg86rerPKZ500yO1d6/v1", // Okta URL - QA
  oktaClientId: "0oahk097iVUKMc7kX1d6", // Okta client ID - QA
  version: "<<VERSION>>",
  googleAnalyticsKey:"266853667",
  redirectUrlInternalUser: "https://fcs-dnbus.cs9.force.com/support/?Origin=DIVR",
  ipUrl: "https://ipapi.co/json/",
  driftAccount: "rdvdcsf9mkra",
  driftPlaybook: 271453,
  driftCaseOrigin: "Chat (Digital IVR)",
  loginFailureScenario: "https://fcs-dnbus.cs9.force.com/support/?prod=sso&origin=divr",
  domain: "dev-service.dnb.com",
  googleMeasurementId: "G-EY52611TBT"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
