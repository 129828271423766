import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2
} from "@angular/core";
import { HomeService } from "../../services/home/home.service";
import { Router, Params, ActivatedRoute, NavigationEnd } from "@angular/router";
import { AppUtil } from "src/app/core/utils/utils";
import { Channel } from "src/app/shared/interfaces/channel-list";
import { DropDownPanelComponent } from "./drop-down-panel/drop-down-panel.component";
import { Subject } from "rxjs";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { UserService } from '../../services/user.service';
import { AccordionPanelComponent } from "ngx-bootstrap/accordion";
import { JsonPipe } from '@angular/common';
import { CommonService } from "src/app/shared/services/common/common.service";
import { takeUntil } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SearchService } from '../../../search/services/search/search.service';
import { DomSanitizer} from '@angular/platform-browser'

// declare ga as a function to set and sent the events
declare let gtag: Function;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLInputElement>;
  @ViewChild('myDrift') myDrift: ElementRef<HTMLInputElement>;
  @ViewChild('hideDrift') hideDrift: ElementRef<HTMLInputElement>;
  @ViewChild('logoutDrift') logoutDrift: ElementRef<HTMLInputElement>;
  public channelMapData?: Array<Channel>;
  public dykData: Array<any> = [];
  finalData: any;
  toShow: any;
  optionHeading: any;
  public dykContent: any;
  clearDropdown: Subject<boolean> = new Subject();
  changeDropdown: Subject<boolean> = new Subject();
  isDykData: Subject<boolean> = new Subject();
  isDefaultLoad: boolean;
  public tabObj = { 'firstDrop': '', 'secondDrop': '', 'thirdDrop': '' };
  @ViewChild('dropDownPanel') dropDownPanel: DropDownPanelComponent | any;
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  public faqContent: Array<any> = [];
  public showFormForPrimary = false;
  public showFormForSecondary = false;
  public five9Url: any;
  public phoneFormData: any;
  public showNotification = false;
  public notificationMessage: string;
  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  public phoneForm: UntypedFormGroup;
  public number: string;
  public phoneDetails: any;
  public submitted = false;
  public formData: any;
  public thumbUpImageSrc: any;
  public thumbDownImageSrc: any;
  public dialCode: any;
  public errorMessage: any;
  public showValidation = false;
  public selectedTab: Channel;
  public showLoader = false;
  public telephoneData: any;
  @ViewChild('group1', { static: false }) group1: AccordionPanelComponent;
  public warningMessage: string;
  /**
  * Holds the subscription array to unsubscribe on destroy
  */
  private unsubscribe$ = new Subject();
  public formattedNumber: number;
  public userDetails: any;
  public userCompany: any;
  public locationData: any;
  public locationIp: any;
  public locationCountry: any;
  public serverError = false;
  public featureList: Array<any>;
  public helpfulLinksList: Array<any>;
  public tabDetails: any;
  public isDYKContent: Subject<boolean> = new Subject();
  public clickedTitle: any;
  public fieldsetDisabled = true;
  public officeOpen: any;
  public officeClosed: any;
  public startDay: any;
  public endDay: any;
  public startTime: any;
  public endTime: any;
  public estDay: any;
  public channelList: any[] | undefined;
  public firstDrop: any;
  public firstDropData: any;
  isShowSecondDrop: any;
  secondChannelList: any;
  showSelectedOption: boolean;
  isShowThirdDrop: any;
  secondDropData: any;
  public thirdChannelList: any;
  thirdDropData: any;

  constructor(
    public homeService: HomeService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    public uService: UserService,
    public commonService: CommonService,
    public http: HttpClient,
    private ngxService: NgxUiLoaderService,
    private searchService: SearchService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    //check for iss query param with sso.dnb.com
    if (paramMap.get('iss')?.includes('sso.dnb.com')) {
      if (!this.authService.isAuthenticated()) {
        //call login method
        this.uService.doLogin("TAB_02");
      } else {
          this.router.navigate(['home']);
      }
    }

    this.isDykData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        this.processData();
      });
    this.phoneForm = this.formBuilder.group({
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      emailID: new UntypedFormControl("", [Validators.required, Validators.email]),
      telephoneNumber: new UntypedFormControl("", Validators.required),
      companyName: new UntypedFormControl(""),
    });

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('event', 'page_view', {
            page_title: 'Digital IVR',
            page_location: this.router.url,
            page_path: this.router.url
          })
        }
      });
  }

  ngOnInit (): void {
    setTimeout(() => {
      this.loadFormData();
    }, 3000);
    this.getHomeDataTask();
    this.getSfdcData(); // commented it during local testing
    this.http.get(environment.ipUrl).subscribe((res: any) => {
      AppUtil.saveToSession("locationData", res);
      this.locationData = res;
      this.locationIp = this.locationData?.ip ? this.locationData?.ip : "";
      this.locationCountry = this.locationData?.country_name ? this.locationData?.country_name.replace(/\s/g, "") : "UnitedStates";
      this.uService.emitEvent(this.locationIp, "ClientId value", "ClientId", 7);
    });
    this.initDropdownData();
    if (this.clearDropdown) {
      this.clearDropdown.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
      //  this.clearDropDownValues();
      });
    }

    if (this.changeDropdown) {
      this.changeDropdown.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
        this.initDropdownData();
      });
    }


    this.tabDetails = localStorage.getItem("tab_details");
    this.tabDetails = JSON.parse(this.tabDetails);
    if (this.tabDetails) { 
      this.selectedFirstOption(this.tabDetails.firstDrop);
      if (this.firstDropData && this.secondChannelList && this.secondChannelList?.length > 0) {
        this.changeSecondOption(this.tabDetails.secondDrop)
      }
      if (this.secondDropData && this.thirdChannelList && this.thirdChannelList?.length > 0) {
        this.selectedThirdOption(this.tabDetails.thirdDrop)
      }
    }
  }
  changeFirstOption(value: any) {
    // this.clearSecondDrop();
    // this.clearThirdDrop();
    this.selectedFirstOption(value);
  }

  /**
   * @description Method to initialize data
   */
   public initDropdownData() {
    // this.channelList = this.item?.Channel_List;
    const prevTabSession = AppUtil.getFromSession("previousTab");
    const prevTab =
      "TAB_ID=" +
      (typeof prevTabSession == "string"
        ? prevTabSession
        : JSON.stringify(prevTabSession));
    //if (AppUtil.isStringInString(this.router.url, prevTab)) {
      // Same URL refresh page
      const firstDrop = AppUtil.getFromSession("firstDrop");
      // console.log("firstDrop ", firstDrop)
      if (firstDrop && Object.keys(firstDrop).length > 0) {
        this.selectedFirstOption(firstDrop);
      }
      const secondDrop = AppUtil.getFromSession("secondDrop");
      // console.log("secondDrop ", secondDrop)
      if (secondDrop && Object.keys(secondDrop).length > 0) {
        this.isShowSecondDrop = true;
        this.selectedSecondOption(secondDrop);
      }
      const thirdDrop = AppUtil.getFromSession("thirdDrop");
      // console.log("thirdDrop ", thirdDrop)
      if (thirdDrop && Object.keys(thirdDrop)?.length > 0) {
        this.isShowThirdDrop = true;
        this.selectedThirdOption(thirdDrop);
      } else {
        this.isShowSecondDrop = false;
        this.isShowThirdDrop = false;
      }
  }

  /**
   * @description Method to clear all dropdown values
   */
  public clearDropDownValues() {
    this.firstDropData = null;
    this.secondDropData = null;
    this.thirdDropData = null;
  }

  public getOutOfOfficeSupportHours(finalData: any): void {
    const options: Intl.DateTimeFormatOptions =  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    timeZone: 'America/New_York', timeZoneName: 'short' };
    const timeStyle = { timeStyle: 'long', timeZone: 'America/New_York', hour12: false } as const;
    
    const dt = new Date().toLocaleString("en-US", options);
    const time = new Date().toLocaleString("en-US", timeStyle);
    const weekday= ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  
    const estDay = dt.split(" ")[0].slice(0, -1);
    const estTime = time.split(" ")[0].split(":");

    for(let i=0; i <= weekday.length; i++) {
      if(estDay === weekday[i]) {
        this.estDay = i;
      }
    }
    
    if (finalData?.data?.primary === 'Request a Call') {
      this.startDay = finalData?.data?.primary_startDay;
      this.endDay = finalData?.data?.primary_endDay;
      for(let i=0; i <= weekday.length; i++) {
        if(finalData?.data?.primary_startDay === weekday[i]) {
          this.startDay = i;
        }
        if(finalData?.data?.primary_endDay === weekday[i]) {
          this.endDay = i;
        }
      }
      this.startTime = finalData?.data?.primary_officeStartTime;
      this.endTime = finalData?.data?.primary_officeEndTime;
    } else if (finalData?.data?.secondary === 'Request a Call') {
      this.startDay = finalData?.data?.secondary_startDay;
      this.endDay = finalData?.data?.secondary_endDay;
      for(let i=0; i <= weekday.length; i++) {
        if(finalData?.data?.secondary_startDay === weekday[i]) {
          this.startDay = i;
        }
        if(finalData?.data?.secondary_endDay === weekday[i]) {
          this.endDay = i;
        }
      }
      this.startTime = finalData?.data?.secondary_officeStartTime;
      this.endTime = finalData?.data?.secondary_officeEndTime;
    }

    const openingTime = this.startTime ? this.startTime?.split(':').map(function (item: any) {
      return parseInt(item, 10);
    }) : "";
    this.officeOpen = openingTime[0] * 60 + openingTime[1];

    const closingTime = this.endTime ? this.endTime?.split(':').map(function (item: any) {
      return parseInt(item, 10);
    }) : "";
    this.officeClosed = closingTime[0] * 60 + closingTime[1];

    const now = Number(estTime[0]) * 60 +  Number(estTime[1]);
    if (this.officeOpen <= now && now <= this.officeClosed && this.estDay >= this.startDay && this.estDay <= this.endDay) {
      this.fieldsetDisabled = false;
    } else {
      this.fieldsetDisabled = true;
    }
  }

  ngAfterViewInit(): void {
    this.clickEvent();
    if (!this.authService.isAuthenticated()) {
      AppUtil.saveToLocal("licensedUser", "false");
    }
    AppUtil.saveToSession('driftDiv', this.myDiv.nativeElement.id);
    AppUtil.saveToSession('logoutDrift', this.logoutDrift.nativeElement.id);
   
    const listElement = document.querySelector('#dynamicTabs ul');
    listElement?.removeAttribute('role');    
  }

  ngAfterViewChecked(): void {
    const anchorElement = document.querySelectorAll("#dynamicTabs li.nav-item");
    if(anchorElement != null){
        anchorElement.forEach((ele) => {
          if(ele.getAttribute('role') != 'tablist'){
           ele?.setAttribute('role','tablist');
          }
        });
      }
      let width = 0;
      let width1 = 0;
      const TAB_01_link = this.el.nativeElement.querySelector('#dynamicTabs li #TAB_01-link');
      if(TAB_01_link != null){
        width = TAB_01_link.offsetWidth;
      }
      const TAB_02_link = this.el.nativeElement.querySelector('#dynamicTabs li #TAB_02-link');
      if(TAB_02_link != null){
        width1 = TAB_02_link.offsetWidth;
      }
      const TAB_02_dropdown = document.querySelector('tab#TAB_02 .dropdown');
      if(TAB_02_dropdown != null){
        this.renderer.setStyle(TAB_02_dropdown, 'left', width+'px');
      }
      const TAB_03_dropdown = document.querySelector('tab#TAB_03 .dropdown');
      if(TAB_03_dropdown != null){
        this.renderer.setStyle(TAB_03_dropdown, 'left', width+width1+'px');     
      }
    }


  public clickEvent(): void {
    this.isDYKContent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        const tabId = "dyk-content-" + this.selectedTab.TAB_ID
        const dykContent = document.getElementById(tabId);
        const anchor = dykContent?.getElementsByTagName("a");
        const arr = anchor ? Array.from(anchor) : [];
        for (let i = 0; arr && i < arr?.length; i++) {
          arr[i].removeEventListener('click', this.handleAnchorClick.bind(this));
          arr[i].addEventListener('click', this.handleAnchorClick.bind(this, arr[i]));
        }
      });
  }

  /**
   * Method to handle click in article content anchor tags
   */
  public handleAnchorClick(data: any): void {
    const sfdcLink = data.getAttribute("href");
    // for GA
    this.clickedTitle = data.innerHTML.replace('&nbsp;', ' ').replace("<u>", " ").replace("</u>", " ");
    const titleId = "dyk-title-" + this.selectedTab.TAB_ID
    const heading = document.getElementById(titleId)?.innerHTML;
    // for GA
    this.uService.emitEvent(this.selectedTab.TAB_NAME + ">>>" + heading, this.selectedTab.TAB_NAME + ">>>" + heading + ">>>" + this.clickedTitle + ">>>" + sfdcLink, "dykArticle", 12);
    // for GA
  }

  /**
   * @description convenience getter for easy access to form fields
   */
  get f () {
    return this.phoneForm.controls;
  }

  /**
   * @description Method to get channelMap data from API
   */
  public getHomeDataTask (): void {
    this.homeService.getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          const channelMapData = resp.channelList;
          this.featureList = resp?.featureList[0]?.Channel_List;
          this.helpfulLinksList = resp?.helpfulLinksList[0]?.Channel_List;
          this.initData(channelMapData);
        }
      },
        (err) => {
          if (err?.error?.StatusCode === "500") {
            this.serverError = true;
          }
        });
  }

  /**
   * @description Initialize data
   */
  public initData (channelMapData: Array<Channel>): void {
    let param: string;
    const id = "TAB_ID";
    let selectedTab: Channel;
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        param = params[id];
        if (param && channelMapData && channelMapData.length > 0) {
          selectedTab = AppUtil.getItemInObjectArray(
            channelMapData,
            id,
            "string",
            param
          );
          selectedTab.active = true;
        } else {
          // this.initDefaultLoad(channelMapData[1]);
          // channelMapData[1].active = true;
          // AppUtil.saveToSession("previousTab", "TAB_02"); // Set prev tab during initial load
          // this.clearValues(); //for clearing data when chnaged url to default
        }
        this.selectedTab = selectedTab;
      });
    this.channelMapData = channelMapData;
    if (this.authService.isAuthenticated()) {
      this.uService.loadDriftChat(AppUtil.getFromSession('driftDiv'))
    }
    this.uService.hideDriftChat(this.hideDrift.nativeElement);
    
    const firstDropOption = AppUtil.getFromSession("firstDropOption");
    if (firstDropOption && Object.keys(firstDropOption).length > 0) {
    // if(JSON.stringify(AppUtil.getFromSession("firstDropOption")) != '{}'){
      this.selectedOptionNew  = firstDropOption;
    }
  }

  /**
   * @description Method to get dyk data from API
   */
  public getSfdcData (): void {
    this.homeService.getSfdcData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          this.dykData = resp.records;
          this.isDykData.next(true);
          setTimeout(() => {
            this.commonService.initQualtrics();
          }, 3000);
        }
      });
  }

  /**
   * @description Method to get drop down emitted data
   */
  public dropDownLoaded (data: any): void {
    this.finalData = data;
    this.getOutOfOfficeSupportHours(this.finalData);
    if (this.finalData) {
      this.showValidation = false;
      this.checkRequestCallChannel();
      if (this.group1) {
        this.group1.isOpen = false;
      }
    }
    this.showNotification = false;
    this.notificationMessage = "";
    this.errorMessage = "";
    this.uService.hideDriftChat(this.hideDrift.nativeElement);

    const elements = document.querySelectorAll('.drift-open-chat');
    elements.forEach(el => {
      el.removeAttribute('disabled');
    });
    this.loadFormData();
    if (this.dykData && this.dykData.length > 0) {
      this.isDykData.next(true);
    }
    this.clearMessage();
    this.commonService.initQualtrics();
  }

  /**
   * @description Method to process data to show in UI
   */
  public processData (): void {
    this.toShow = this.finalData?.toShow;
    this.dykContent = "";
    this.faqContent = [];
    this.showFormForPrimary = false;
    this.showFormForSecondary = false;
    if (this.finalData?.data?.reason) {
      this.optionHeading = this.finalData?.data?.dykTitle;
    } else if (this.finalData?.data?.subreason) {
      this.optionHeading = this.finalData?.data?.dykTitle;
    }
    if (
      this.finalData?.data?.hasDyk === true &&
      this.finalData?.data?.dykTitle
    ) {
      for (let i = 0; i <= this.dykData?.length; i++) {
        if (
          this.dykData &&
          this.dykData[i]?.Title === this.finalData?.data?.dykTitle
        ) {
          this.dykContent = this.sanitizer.bypassSecurityTrustHtml(this.dykData[i]?.Content);
        }
      }
      setTimeout(() => {
        this.isDYKContent.next(true);
      }, 1500);
    }
    // for GA
    if (this.toShow) {
      const selectedOption = this.emitGAEvent();
      this.uService.emitEvent(selectedOption, "Selected Option", "click", 3);
    }
    // for GA
    this.checkRequestCallChannel();
  }

  /**
   * @description Method to trigger when tab changed
   */
  public changeTab (selectedTab: Channel): void {
    this.uService.hideDriftChat(this.hideDrift.nativeElement);
    //}
    // for GA
    this.uService.emitEvent(selectedTab?.TAB_NAME, "Selected Tab", "click", 1);
    // for GA
    if (this.authService.isAuthenticated()) {
      this.uService.loadDriftChat(AppUtil.getFromSession('driftDiv'))
    }
    this.uService.hideDriftChat(this.hideDrift.nativeElement);
    const elements = document.querySelectorAll('.drift-open-chat');
    elements.forEach(el => {
      el.removeAttribute('disabled');
    });
    // this.toShow = false;
    // this.showFormForPrimary = false;
    // this.showFormForSecondary = false;
    // this.faqContent = [];
    // this.finalData = "";
    // this.showNotification = false;
    // this.notificationMessage = "";
    // this.errorMessage = "";
    // localStorage.removeItem("firstDrop");
    // localStorage.removeItem("secondDrop");
    // localStorage.removeItem("thirdDrop");
    // this.clearValues();
    this.changeQueryParam(selectedTab);
    this.saveTabData(selectedTab);
    // this.showValidation = false;
    this.commonService.initQualtrics();
  }

  /**
   * @description Method to trigger when tab deselected
   */
  public deselectTab (selectedTab: Channel): void {
    // this.clearValues();
  }

  /**
   * @description Method to manage page default load
   */
  public initDefaultLoad (selectedtab: Channel): void {
    this.changeQueryParam(selectedtab);
  }

  /**
   * @description Method to save tab data
   */
  public saveTabData (selectedTab: Channel): void {
    AppUtil.saveToSession("previousTab", selectedTab.TAB_ID || "");
    this.selectedTab = selectedTab;
  }

  /**
   * @description Method to clear saved values
   */
  public clearValues (): void {
    const prevTabSession = AppUtil.getFromSession("previousTab");
    const prevTab = "TAB_ID=" + (
      typeof prevTabSession == "string"
        ? prevTabSession
        : JSON.stringify(prevTabSession));
    if (!AppUtil.isStringInString(this.router.url, prevTab)) {
      this.clearSessionStorage();
      this.selectedOptionNew = null;
      this.clearDropdown.next(true);
    } else {
      this.tabDetails = localStorage.getItem("tab_details");
      this.tabDetails = JSON.parse(this.tabDetails);
      if (this.tabDetails && !AppUtil.isStringInString(this.router.url, this.tabDetails?.TAB_ID)) {
        this.clearSessionStorage();
        this.selectedOptionNew = null;
        this.clearDropdown.next(true);
        if(this.tabDetails) {
          localStorage.removeItem("tab_details");
        }
      }
    }
  }

  /**
   * Method to clear dropdown values from session storage
   */
  public clearSessionStorage(): void {
    AppUtil.deleteSessionStorage("firstDrop");
    AppUtil.deleteSessionStorage("secondDrop");
    AppUtil.deleteSessionStorage("firstDropOption");
    AppUtil.deleteSessionStorage("secondDropOption");
    AppUtil.deleteSessionStorage("thirdDrop");
    AppUtil.deleteSessionStorage("thirdDropOption");
  }

  /**
   * @description Method to change query param
   * @param selectedTab Selected tab
   */
  public changeQueryParam (selectedTab: Channel): void {
    const queryParams: Params = { TAB_ID: selectedTab.TAB_ID };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
    this.channelList = selectedTab?.Channel_List;
    const prevTabSession = AppUtil.getFromSession("previousTab");
    const prevTab =
      "TAB_ID=" +
      (typeof prevTabSession == "string"
        ? prevTabSession
        : JSON.stringify(prevTabSession));
    //if (AppUtil.isStringInString(this.router.url, prevTab)) {
      // Same URL refresh page
      // AppUtil.saveToSession("firstDrop","");
      // if (firstDrop && Object.keys(firstDrop).length > 0) {
        // this.selectedFirstOption(firstDrop);
      // }
      // this.secondDropData = null;
      // this.thirdDropData = null;
  }

  /**
 * Method to redirect from primary and secondary actions
 * @param data redirection data
 */
public redirectTo (data: any, type: string, event?: any): void {
  let userData = {
    emailID: ""
  };
  if (this.authService.isAuthenticated()) {
    userData = JSON.parse(AppUtil.getFromLocal("_user"));
  }
  let primaryRedirectUrl = "";
  let secondaryRedirectUrl = "";
  let channelValue = "";
  let url = "";

  if (type === "primary") {
    primaryRedirectUrl = data.primary_action;
    channelValue = data?.primary;
    if (data?.primary_sendCustomerDetails) {
      url = data?.primary_action;
      primaryRedirectUrl = this.commonService.getAppendedUrl(url, userData);
    } else if (data?.primary_addEmailId) {
      primaryRedirectUrl = data.primary_action + userData["emailID"];
    }
    if (data?.primary_appendURL && data?.primary_appendURL !== "") {
      primaryRedirectUrl += data?.primary_appendURL;
    }
    this.openUrl(primaryRedirectUrl, event);
  } if (type === "secondary") {
    secondaryRedirectUrl = data.secondary_action;
    channelValue = data?.secondary;
    if (data?.secondary_sendCustomerDetails) {
      url = data?.secondary_action;
      secondaryRedirectUrl = this.commonService.getAppendedUrl(url, userData);
    } else if (data?.secondary_addEmailId) {
      secondaryRedirectUrl = data.secondary_action + userData["emailID"];
    }
    if (data?.secondary_appendURL && data?.secondary_appendURL !== "") {
      secondaryRedirectUrl += data?.secondary_appendURL;
      channelValue = data?.secondary;
    }
    this.openUrl(secondaryRedirectUrl, event);
  } 
  // for GA
  const selectedOption = this.emitGAEvent();
  this.uService.emitEvent(selectedOption+" >>> "+channelValue, type, "Channel click", 2);
  // for GA
}

  /**
   * Method to redirect the channel
   * @param url url to redirect
   */
  public openUrl (url: string, event?: any): void {
    let target;
    if(event) {
      target = event.target || event.srcElement || event.currentTarget;
    }
    if (target?.classList?.contains('drift-open-chat')) {
      this.showLoader = true;
      target.setAttribute('disabled', true);
      setTimeout(() => {
        this.showLoader = false;
      }, 3000);
      this.uService.loadDriftPlaybook(this.myDrift.nativeElement);
    } else {
      target?.removeAttribute('disabled');
      window.open(url, "_blank");
    }
  }

  /**
   * Method to request call
   * @param selectedData Selected dropdown data
   */
  public requestCall (selectedData: any): void {
    this.submitted = true;
    let action;
    let f9CallGroup;
    let channelValue = ""; // for GA
    let type = ""; // for GA
    this.clearMessage();
    if (this.phoneForm.invalid) {
      this.showValidation = true;
      return;
    } else {
      this.phoneFormData = this.phoneForm.value;
      this.phoneDetails = this.phoneFormData?.telephoneNumber;
      this.number = this.phoneDetails?.e164Number.replace(
        this.phoneDetails?.dialCode,
        ""
      );
      this.dialCode = this.phoneDetails?.dialCode.replace(/[^a-z\d\s]+/gi, "");
      this.number =
        this.phoneDetails?.countryCode === "US" ||
          this.phoneDetails?.countryCode === "CA"
          ? this.number
          : "011" + this.dialCode + this.number;
      if (this.finalData?.data?.primary === "Request a Call") {
        action = selectedData?.primary_action;
        f9CallGroup = selectedData?.primary_F9CallGroup;
        // for GA
        channelValue = this.finalData?.data?.primary;
        type = "primary";
        // for GA
      }
      if (this.finalData?.data?.secondary === "Request a Call") {
        action = selectedData?.secondary_action;
        f9CallGroup = selectedData?.secondary_F9CallGroup;
        // for GA
        channelValue = this.finalData?.data?.secondary;
        type = "secondary";
        // for GA
      }
      // for GA
      const selectedOption = this.emitGAEvent();
      this.uService.emitEvent(selectedOption+" >>> "+channelValue, type, "Channel click", 2);
      // for GA
      this.phoneFormData.companyName = this.phoneFormData?.companyName ? this.phoneFormData?.companyName.replaceAll("&", "%26") : null;
      if (f9CallGroup && this.number) {
        this.ngxService.start();
        this.homeService
          .requestCall(f9CallGroup, this.number, this.phoneFormData)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (resp) => {
              this.phoneFormData.telephoneNumber = "";
              this.showValidation = false;
              setTimeout(() => {
                this.loadFormData();
              }, 1000);
              if (this.authService.isAuthenticated()) {
              
                //storing telephoneNumber in localStorage
                //need to be commented
                // localStorage.setItem(
                //   "telephoneNumber",
                //   JSON.stringify(this.phoneFormData?.telephoneNumber)
                // );

                if (localStorage.getItem("_user")) {
                  this.userDetails = localStorage.getItem("_user");
                  if (JSON.parse(this.userDetails)?.companyName || JSON.parse(this.userDetails)?.companyName === "") {
                    localStorage.setItem("_userCompanyName",
                      JSON.stringify(this.phoneFormData?.companyName)
                    );
                  }
                }
              } else {
                localStorage.setItem(
                  "nonSsoUser",
                  JSON.stringify(this.phoneFormData)
                );
              }
              if (resp && resp?.StatusCode && resp?.StatusCode === "0") {
                this.showNotification = true;
                this.notificationMessage = resp?.StatusMessage;
              } else if (resp && resp?.StatusCode && resp?.StatusCode === "600") {
                this.warningMessage = resp?.StatusMessage;
              }
              this.ngxService.stop();
            },
            (err) => {
              if (err?.error?.StatusCode === "500") {
                this.ngxService.start();
                this.errorMessage = err?.error?.StatusDescription;
                this.phoneFormData.telephoneNumber = "";
                this.showValidation = false;
                setTimeout(() => {
                  this.loadFormData();
                  this.ngxService.stop();
                }, 1000);
              }
            }
          );
      }
    }
  }

  /**
   * Method to clear notification message in form
   */
  public clearMessage (): void {
    this.showNotification = false;
    this.notificationMessage = "";
    this.errorMessage = "";
    this.warningMessage = "";
  }

  /**
   * Method to load the form data on page load and tab change
   */
  public loadFormData (): void {
    if (this.authService.isAuthenticated()) {
      this.formData = localStorage.getItem("_user");
      this.formData = JSON.parse(this.formData);
      this.telephoneData = localStorage.getItem("telephoneNumber");
      this.telephoneData = JSON.parse(this.telephoneData);
      this.formattedNumber = this.telephoneData ? this.telephoneData?.e164Number.replace(
        this.telephoneData?.dialCode,
        "") : null;
      if (localStorage.getItem("_userCompanyName")) {
        this.userCompany = localStorage.getItem("_userCompanyName")
        this.formData.companyName = JSON.parse(this.userCompany);
      }
    } else if (localStorage.getItem("nonSsoUser")) {
      this.formData = localStorage.getItem("nonSsoUser");
      this.formData = JSON.parse(this.formData);
      this.formattedNumber = this.formData?.telephoneNumber ? this.formData?.telephoneNumber?.e164Number.replace(
        this.formData?.telephoneNumber?.dialCode,
        "") : null;
    }
    this.phoneForm.setValue({
      firstName: this.formData?.firstName ? this.formData?.firstName : null,
      lastName: this.formData?.lastName ? this.formData?.lastName : null,
      emailID: this.formData?.emailID ? this.formData?.emailID : null,
      telephoneNumber: this.formattedNumber ? this.formattedNumber : null,
      companyName: this.formData?.companyName ? this.formData?.companyName : null,
    });
  }

  /**
   * Method to check the channel is Request a call or not
   */
  public checkRequestCallChannel (): void {
    if (this.finalData?.data?.primary === "Request a Call") {
      this.showFormForPrimary = true;
    } else {
      this.showFormForPrimary = false;
    }
    if (this.finalData?.data?.secondary === "Request a Call") {
      this.showFormForSecondary = true;
    } else {
      this.showFormForSecondary = false;
    }
  }

  /**
   * Method to manipulate and emit dropdown data Google analytics
   */
  public emitGAEvent () {
    let selectedOption = "";
    if (!AppUtil.isEmptyObject(AppUtil.getFromSession('firstDropOption'))) {
      selectedOption += AppUtil.getFromSession('firstDropOption');
    }
    if (!AppUtil.isEmptyObject(AppUtil.getFromSession('secondDropOption'))) {
      selectedOption += "  >>>  ";
      selectedOption += AppUtil.getFromSession('secondDropOption');
    }
    if (!AppUtil.isEmptyObject(AppUtil.getFromSession('thirdDropOption'))) {
      selectedOption += "  >>>  ";
      selectedOption += AppUtil.getFromSession('thirdDropOption');
    }
    return selectedOption;
  }
    selectedOptionNew: any;
    showDropdownFlag: boolean | any;
    selectFirstDrop: any;
    
    showDropdown(tab: any) {
      // if(tab.active) {
        this.showDropdownFlag = true;
      // }
    }
    hideDropdown() {
       this.showDropdownFlag = false;
    }
    selectOption(option: any) {
      this.clearDropDownValues();
      this.showDropdownFlag = false;
      this.selectFirstDrop = option;
      this.showSelectedOption = true;
      this.selectedOptionNew = option.reason;
      AppUtil.saveToSession("firstDrop", option);
      AppUtil.saveToSession("firstDropOption", option?.reason);
      this.selectedFirstOption(option);
    }
    public dropDownLoadedHome (dataHome: any): void {
      this.finalData = dataHome;
      this.getOutOfOfficeSupportHours(this.finalData);
      if (this.finalData) {
        this.showValidation = false;
        this.checkRequestCallChannel();
        if (this.group1) {
          this.group1.isOpen = false;
        }
      }
      this.showNotification = false;
      this.notificationMessage = "";
      this.errorMessage = "";
      // this.uService.hideDriftChat(this.hideDrift.nativeElement);
  
      const elements = document.querySelectorAll('.drift-open-chat');
      elements.forEach(el => {
        el.removeAttribute('disabled');
      });
      this.loadFormData();
      if (this.dykData && this.dykData.length > 0) {
        this.isDykData.next(true);
      }
      this.clearMessage();
      this.commonService.initQualtrics();
    }

    changeSecondOption(value: any) {
      this.clearThirdDrop();
      this.selectedSecondOption(value);
    }
    selectedSecondOption(value: any){
      this.secondDropData = value;
      this.thirdDropData = null;
      AppUtil.saveToSession("secondDrop", this.secondDropData);
      AppUtil.saveToSession("secondDropOption", this.secondDropData?.subreason);
    this.isShowThirdDrop =
      this.secondDropData?.hasSubreason &&
      this.secondDropData?.subreasons?.length > 0;
    if (this.isShowThirdDrop) {
      this.thirdChannelList = this.secondDropData?.subreasons;
      this.dropDownLoadedHome({data: [], toShow: false});
    } else {
      this.thirdChannelList = [];
        this.dropDownLoadedHome( { data: this.secondDropData, toShow: true });
    }
    }
    selectedFirstOption(value: any) {
      this.firstDropData = value;
      // console.log("firstDropData selectedFirstOption"+ this.firstDropData);
      AppUtil.saveToSession("firstDrop", this.firstDropData);
      AppUtil.saveToSession("firstDropOption", this.firstDropData?.reason);
      if(this.firstDropData){
        this.isShowSecondDrop =
        this.firstDropData?.hasSubreason &&
        this.firstDropData?.subreasons?.length > 0;
      }
      if (this.isShowSecondDrop) {
        this.secondChannelList = this.firstDropData?.subreasons;
        this.dropDownLoadedHome({ data: [], toShow: false });
      } else {
        this.secondChannelList = [];
        this.dropDownLoadedHome( { data: this.firstDropData, toShow: true });
     }
    }
    public selectedThirdOption(value: any) {
      this.thirdDropData = value;
      AppUtil.saveToSession("thirdDrop", this.thirdDropData);
      AppUtil.saveToSession("thirdDropOption", this.thirdDropData?.subreason);
      this.dropDownLoadedHome( { data: this.thirdDropData, toShow: true });
    }
    public clearData(): void {
      localStorage.removeItem("tab_details");
    }

    public clearSecondDrop() {
      this.secondDropData = null;
      AppUtil.deleteSessionStorage("secondDrop");
      AppUtil.deleteSessionStorage("secondDropOption");
    }
    public clearThirdDrop() {
      this.thirdDropData = null;
      AppUtil.deleteSessionStorage("thirdDrop");
      AppUtil.deleteSessionStorage("thirdDropOption");
    }
  /**
    * Unsubscribe the Observable
    */
  public ngOnDestroy (): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  isTouchDevice(): boolean {
    return 'ontouchstart' in window;
  }
  handleMouseOver(item: any){
    if (!this.isMobileOrTouch()) {
      item.active = true;
      this.showDropdown(item);
      this.channelList = item?.Channel_List;
    }
  }
  handleClick(item: any){
      this.toggleDropdown(item);
  }
  toggleDropdownFlag: boolean = false;
  toggleDropdown(tab: any) {
    const prevTabSession = AppUtil.getFromSession("previousTab");
    // if(prevTabSession === tab.TAB_ID){
    //   this.showDropdownFlag = !this.showDropdownFlag;
    // } else {
      this.showDropdownFlag = true;
    // }
    this.changeQueryParam(tab);
  }
  private isMobileOrTouch(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
