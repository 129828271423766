<div class="search-results-container">
    <div class="ml-1 mb-2 search-results-heading text-3xl">What is a D-U-N-S?</div>
    <!-- <div
      class="col-sm-12 mt-3 p-0 m-b-10"
      id="search-feedback1"
    ></div> -->
    <div
                  class="col-sm-12 mt-3 p-0"
                  id="q-dyk-TAB_01"
                ></div>
    <div class="search-results-heading row ml-1 mb-3 mt-3 text-3xl" *ngIf="allSearchResults?.length > 0 && correctedKeyword; else keyword">
        Search results for "{{ this.correctedKeyword }}"<span class="text-lg pt-2">, instead of "{{allSearchedData?.keyword}}"</span>
    </div>
    <ng-template #keyword>
      <div class="search-results-heading row ml-1 mb-3 mt-3 text-3xl" *ngIf="allSearchResults?.length > 0">
        Search results for "{{allSearchedData?.keyword}}"
      </div>
    </ng-template>
    <div class="ml-0 pl-0 container-fluid">
      <div class="row">
        <div *ngFor="let search of allSearchResults" class="col-xs-12 col-sm-12 col-md-7 text-lg ml-1">
          <a class="text-md link-style search-results" [innerHTML]="search['highlight.Title'] ? search['highlight.Title'] : search['Title']" 
          (click)="redirectTitle(search['Title'])"
           >
            <!-- <ng-template #popTemplate>
              <button type="button" class="btn-close close pull-right close-alignment" aria-label="Close" (click)="pop.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
              <div id="search-header" class="title-text text-l" [innerHTML]="search['Title']">
              </div>
              <p class="col-md-12 text-md pt-3 mb-0 pl-0 popover-content login-text-color" [innerHTML]="search['Content']">
              </p>
            </ng-template> -->
          </a>
      </div>
      </div>
      <div class="cursor-pointer show-more text-nm float-left" *ngIf="showMore" (click)="showMoreContent()">Show
        more
        <img height="20" width="20" src="../../../../assets/images/feature/down-arrow.png" 
        alt="update" />
      </div>
  </div>
  
