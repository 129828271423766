<div *ngIf="!serverError" class="d-flex flex-column main-container">
  <div class="banner-container">
    <div class="frequent-title-color text-lg banner-font banner-margin text-center">
      Digital Service Center
    </div>
  </div>

  <div class="home-btn-container d-flex justify-content-lg-between">
    <div class="container-fluid p-0">
      <div class="row pt-2">
        <div
          class="route-title back-navigation col-xs-12 col-sm-4 col-md-8 text-3xl"
        > <span class="back-home" [routerLink]="['/home']" (click)="searchService.emitUrlData('backNavigation')"
        routerLinkActive="router-link-active">
           <img
           height="24"
           width="24"
          alt="Left Arrow"
          class="left-arrow-image"
          src="assets/images/feature/arrow_back.png"
        />
        Home</span>
         
        </div>
        <div class="col-xs-12 col-sm-8 col-md-4 app-search-bar">
          <app-search></app-search>
        </div>
      </div>
    </div>
  </div>

  <div class="search-container">
    <!-- <app-article-content></app-article-content> -->
    <router-outlet></router-outlet>
  </div>
  <div class="helpful-links-container">
    <app-helpful-links [channelMapDataHelfulLink]="helpfulLinksList"></app-helpful-links>
  </div>
</div>