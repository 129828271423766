import { environment } from "../../../environments/environment";
import { InjectionToken, inject } from "@angular/core";
import { AppUtil } from "src/app/core/utils/utils";

export const SERVICE_GLOBAL = new InjectionToken("configuration", {
  factory: () => {
    const baseURL = AppUtil.getAPIBaseUrl();
    const oktaBaseUrl = AppUtil.getOktaBaseUrl();
    const iDaaSUrl = AppUtil.getiDaaSBaseUrl();
    return {
      /* Mock URLs */
      // HOME_DATA: baseURL + "/homeGet", //Mock ChannelMap
      // DYK_DATA: baseURL + "/dykGet" //Mock SFDC DYK/FAQ

      /* Actual URLs */
      //HOME_DATA: baseURL + "/ivr/channelMap",
      HOME_DATA: baseURL + "/ivr/getChannelMap",
      SFDC_DATA: baseURL + "/ivr/sfdcKBArticles",
      LOGIN: baseURL + "/ivr/token",

      /* Okta URLs */
      OKTA_AUTHORIZE: oktaBaseUrl + "/authorize",
      OKTA_LOGOUT: oktaBaseUrl + "/logout",

      /* Five9 URL */
      FIVE9_URL: baseURL + "/ivr/five9",

      /* Create SSO URLs*/
      CREATE_SSO_USER: baseURL + "/ivr/createUser",
      GET_USER: baseURL + "/ivr/getUser",
      ADD_PRODUCT_ACCESS: baseURL + "/ivr/addProductAccess",

      /* Search URLs */
      GET_TITLES: baseURL + "/ivr/search/getTitles",
      GET_SEARCH_DATA: baseURL + "/ivr/search/getSearchData",
      GET_ALL_SEARCH_DATA: baseURL + "/ivr/search/getAllSearchData",
      GET_TAB_DETAILS: baseURL + "/ivr/search/getTabDetails",

      /* Product URLs */
      GET_REASON_DESCRIPTION: baseURL + "/ivr/reasonDetails",

      /* Case URLs */
      GET_CASE_STATUS: baseURL + "/ivr/caseStatus",
      UPDATE_CASE_STATUS: baseURL+ "/ivr/updateCaseStatus",

      /* Gainsight URLs */

      GET_GAINSIGHT_USER_DATA: "https://vi628v719a.execute-api.us-east-1.amazonaws.com/dev/contact/getContacts",

      /* SFDC Contact */
      GET_SFDC_CONTACT: "https://api.uat-service.dnb.com" + "/ivr/identifyUser",

      /* iDaas URLs */
      GET_TOKEN: iDaaSUrl +  "/oauth2/v3/token",
      GET_IDASS_USER: iDaaSUrl + "/user"
    };
  }
});
